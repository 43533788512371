import React from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Container from 'components/shared/Container'
import { Content, Heading } from 'components/shared/Typography'

import type { CookiesPageQuery } from 'types/graphql'

const Section = styled.section`
  padding: 4rem 0;
  ${Container} {
    max-width: 1000px;
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 3rem 0;
    ${Heading} {
      margin-bottom: 1rem;
    }
  }
  ${Content} {
    ${({ theme }) => theme.media.xxl.min} {
      p,
      li {
        font-size: 18px !important;
      }
    }
    p:first-child {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const CookiesPage: React.FC<PageProps<CookiesPageQuery>> = ({ data }) => {
  const PAGE = data?.page
  const PAGE_SEO = data?.page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: PAGE.title! }]} />
      <main>
        <Section>
          <Container as="article" narrow>
            <Heading
              as="h3"
              size={50}
              sizeDiff={0.7}
              weight={500}
              align="center"
              margin="2rem"
              dangerouslySetInnerHTML={{ __html: PAGE.title! }}
            />
            <Content dangerouslySetInnerHTML={{ __html: PAGE.content! }} />
          </Container>
        </Section>
      </main>
    </Layout>
  )
}

export default CookiesPage

export const query = graphql`
  query CookiesPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/cookies/" }
    ) {
      title
      content
      seo {
        ...WpSEO
      }
    }
  }
`
